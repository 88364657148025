window.day_ticket_form = function(day_ticket_limit, extra_service_limit) {
    var self = this;
    var day_ticket_limit            = day_ticket_limit;
    var extra_service_limit         = extra_service_limit;
    var $body                       = $('body');
    var $errors                     = $('.js-errors');
    var $form                       = $('.js-day-ticket-form');
    var $coupon_code_input          = $('.js-coupon-input');
    var $voucher_form               = $('.js-voucher-code-form');
    var $code_error                 = $('.js-code-error');
    var $code_success               = $('.js-code-success');
    var $check_coupon_btn           = $('.js-check-coupon-code-button');
    var $remove_coupon_btn          = $('.js-remove-coupon-code-button');
    var $form_button                = $('.js-form-button');
    var $date_picker                = $('.js-reservation-datepicker');
    var is_loading                  = false;


    var order_items = {'extra_service': {}, 'day_ticket': {}};
    var reservation_date_at = null;

    const formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });

    self.init = function() 
    {
        reservation_date_at = $date_picker.val();

        if (reservation_date_at !== '' && reservation_date_at != null) {
            $voucher_form.removeClass('hide');
        }
        self.checkCouponCode();
        self.updateOrderData();
        $form.on('submit', self.submitForm);
        $body.on('click', '.js-add-item', self.incrementValue);
        $body.on('click', '.js-remove-item', self.decrementValue);
        $body.on('click', '.js-check-coupon-code-button', self.debouncedCheckCouponCode);
        $body.on('click', '.js-remove-coupon-code-button', self.removeCoupon);
        $body.on('change keydown', '.js-item-quantity', self.updateOrderQuantity);
    };

    /**
     * Update input value based on user input changes
     *
     * @author Bas Lokerman <bas@click.nl>
     * @return  void
     */
    self.updateOrderQuantity = function()
    {
        var item_id = $(this).attr('data-location-id');
        var input = $(`.js-item-quantity[data-location-id='${item_id}']`);
        var current_value = parseInt(input.val()) || 0;

        var type = $(`.js-item[data-location-id='${item_id}']`).attr('data-type');
        var max = 500;

        if(type == 'day_ticket'){
            max = day_ticket_limit;
        }
        if (type == 'extra_service') {
            max = extra_service_limit;
        }

        // Ensure the value is a number and within the specified range
        if (isNaN(current_value) || current_value < 0) {
            current_value = 0;
        } else if (current_value > max) {
            current_value = max;
        }

        input.val(current_value);
        self.updateOrderData();
    }

    /**
     * Adjust input value
     *
     * @author Bas Lokerman <bas@click.nl>
     * @return  void
     */
    self.adjustValue = function(adjustment) {
        var item_id = $(this).attr('data-location-id');
        var input = $(`.js-item-quantity[data-location-id='${item_id}']`);
        var current_value = parseInt(input.val()) || 0;

        var type = $(`.js-item[data-location-id='${item_id}']`).attr('data-type');
        
        var max = 500;
        if(type == 'day_ticket'){
            max = day_ticket_limit;
        }
        if (type == 'extra_service') {
            max = extra_service_limit;
        }

        var new_value = Math.max(Math.min(current_value + adjustment, max), 0);
        input.val(new_value);

        self.updateOrderData();
    };

    /**
     * Increment input value by one
     *
     * @author Bas Lokerman <bas@click.nl>
     * @return  void
     */
    self.incrementValue = function() {
        self.adjustValue.call(this, 1);
    };

    /**
     * Decrement input value by one
     *
     * @author Bas Lokerman <bas@click.nl>
     * @return  void
     */
    self.decrementValue = function() {
        self.adjustValue.call(this, -1);
    };

    /**
     * Update the shopping cart items
     *
     * @author Bas Lokerman <bas@click.nl>
     * @return  void
     */
    self.updateOrderData = function()
    {
        var inputs = $('.js-item-quantity');
        // Reset order items
        order_items = {'extra_service': {}, 'day_ticket': {}};

        inputs.each(function() {
            var input = $(this);
            var item = input.closest('.js-item');
            var type = item.attr('data-type');
            var item_id = item.attr('data-location-id');

            if (input.val() != 0) {
                order_items[type][item.attr('data-id')] = input.val();
            } else {
                delete order_items[type][item.attr('data-id')];
            }
        });
        // Call the debounced (with delay) version of updateprices
        self.debouncedUpdatePrices();
    }

    /**
     * Validate and collect prices
     *
     * @author Bas Lokerman <bas@click.nl>
     * @return  void
     */
    self.updatePrices = function()
    {
        var coupon_code = $coupon_code_input.val();

        $.post('/api/day-ticket/update-ticket-price', {coupon_code: coupon_code, reservation_date_at: reservation_date_at, order_items: order_items})
            .done(self.updatePriceSuccess)
            .fail(self.formError);
    };

    /**
     * Check the coupon form for errors
     *
     * @author Bas Lokerman <bas@click.nl>
     * @return  void
     */
    self.checkCouponCode = function()
    {
        var coupon_code = $coupon_code_input.val();
        
        if(coupon_code !== '' && coupon_code != null){
            $code_error.addClass('hide');
            $coupon_code_input.prop('disabled', true);

            $.post('/api/day-ticket/update-ticket-price', {coupon_code: coupon_code, reservation_date_at: reservation_date_at, order_items: order_items})
            .done(function(response) {
                self.updatePriceSuccess(response);
                if(response.data.is_active_discount == true){
                    $code_success.removeClass('hide');
                    $code_error.addClass('hide');
                }else{
                    $code_error.removeClass('hide');
                    $code_success.addClass('hide');
                }
            })
            .fail(self.formError);
        }
    }

    /**
     * Remove the coupon from the form. Update the input fields
     *
     * @author Bas Lokerman <bas@click.nl>
     * @return  void
     */
    self.removeCoupon = function()
    {
        $coupon_code_input.val('');
        $coupon_code_input.prop('disabled', false);
        $code_success.addClass('hide');
        $code_error.addClass('hide');
        $check_coupon_btn.removeClass('hide');
        $remove_coupon_btn.addClass('hide');
        self.updatePrices();
    }

    /**
     * Initialize the datepicket
     *
     * @author Bas Lokerman <bas@click.nl>
     * @param   string locale
     * @param   array unavailable_dates
     * @return  void
     */
    self.setReservationDatePicker = function( locale, unavailable_dates ){
        $('.js-reservation-datepicker').each(function(){
            $(this).datetimepicker({
                lang: locale,
                format:'d-m-Y',
                timepicker: false,
                scrollInput: false,
                minDate: 0, // Today
                closeOnDateSelect: true,
                formatDate:'d-m-Y',
                disabledDates: unavailable_dates,
                maxDate:'+01-12-1970', // One year ahead max.
                onChangeDateTime: function(date, $input) {
                    if (date !== '' && date != null) {
                        $voucher_form.removeClass('hide');
                        reservation_date_at = date.dateFormat('d-m-Y');
                    } else {
                        $voucher_form.addClass('hide');

                        reservation_date_at = null;
                    }
                    self.checkCouponCode();
                }
            });
        });
    };

    /**
     * Update the prices on day-ticket form
     *
     * @author Bas Lokerman <bas@click.nl>
     * @param   object response
     * @return  void
     */
    self.updatePriceSuccess = function(response)
    {
        // Update price data
        $('.js-extra-service-total-price').text(formatter.format(response.data.extra_services_total_price));
        $('.js-total-price').text(formatter.format(response.data.total_price));

        if (response.data.is_active_discount == true) {
            $('.js-day-ticket-total-price').text(formatter.format(response.data.day_ticket_total_price_without_discount));

            $('.js-subtotal-day-ticket').text(formatter.format(response.data.day_ticket_total_price));

            $code_success.removeClass('hide');
            $code_error.addClass('hide');
            $check_coupon_btn.addClass('hide');
            $remove_coupon_btn.removeClass('hide');
            $('.js-discount-price-block').removeClass('hide');
            $('.js-voucher-code-text').text('Kortingscode (' + $coupon_code_input.val() + ')');
            $('.js-voucher-code-discount').text('-' + formatter.format(response.data.day_ticket_total_discount));
        } else {
            $('.js-subtotal-day-ticket').text(formatter.format(response.data.day_ticket_total_price));
            $('.js-discount-price-block').addClass('hide');
            $coupon_code_input.val('');
            $coupon_code_input.prop('disabled', false);
            $code_success.addClass('hide');
            $remove_coupon_btn.addClass('hide');
            $check_coupon_btn.removeClass('hide');
        }
    };

    /**
     * Submit form
     *
     * @author Bas Lokerman <bas@click.nl>
     * @return void
     */
    self.submitForm = function(e)
    {
        e.preventDefault();
        self.updateOrderData();
        $form_button.prop('disabled', true);
        $errors.addClass('hide');
        $coupon_code_input.val();
        $('.js-error').text('');
        var form = $(this);
        var form_data = new FormData($(this)[0]);

        // Append order items to the form data
        for (let type in order_items) {
            for (let key in order_items[type]) {
                form_data.append(`order_items[${type}][${key}]`, order_items[type][key]);
            }
        }
        form_data.append('coupon_code', $coupon_code_input.val());

        if (!is_loading) {
            is_loading = true;
            $.ajax({
                type:'POST',
                url: $(this).attr('action'),
                data: form_data,
                cache: false,
                contentType: false,
                processData: false,
                success: function(data){
                    is_loading = false;
                    // Redirect user if url is available
                    if(data.redirect_url) {
                        window.location.href = data.redirect_url;
                    } else {
                        $errors.find('[data-error-name="global_error_message"]').text(Lang.get('frontend.js.global.error'));
                    }
                },
                error: self.formError
            });
        }
    }

    /**
     * Debounce function that can add a delay to a function (func) and a wait time (in milliseconds)
     *
     * @author Bas Lokerman <bas@click.nl>
     * @param   function  func  Function that will get a delay each time it is called.
     * @param   integer  wait  amount of delay
     * @return  function
     */
    self.debounce = function(func, wait) {
        // Timeout variable to keep track of the last time the function was called
        var timeout;

        // Return a new function that will be the debounced version of the given function
        return function() {
            const context = this;
            const args = arguments;

            clearTimeout(timeout);

            // Set a new timeout that will execute the the given function after the specified wait time
            timeout = setTimeout(function() {
                func.apply(context, args);
            }, wait);
        };
    };

    /**
     * Handle errors
     *
     * @author Bas Lokerman <bas@click.nl>
     * @param   object error
     * @return  void
     */
    self.formError = function(error){
        var errors = [];
        $form_button.prop('disabled', false);
        is_loading = false;

        if (error.responseJSON) {
            errors = error.responseJSON;
        }
        
        $errors.removeClass('hide');
        var error_set = false;
        if(errors != 'undefined' && errors.length !== 0){
            $('html,body').animate( { scrollTop: 0 }, 2000 );
            // loop through every error
            for (var error in errors) {
                // loop through every array element in error
                for (var i = 0; i < errors[error].length; i++) {
                    // Check if an recaptch error is found
                    if (errors.recaptcha != null) {
                        // Find the recaptcha elements and show the error.
                        if ($current_form.children().hasClass('js-captcha')) {
                            error_set = true;
                            $errors.find('[data-error-name="' + error + '"]').text(errors[error][i]);
                        }
                    } else {
                        // If no recaptcha error is found, show the other errors.
                        error_set = true;
                        $errors.find('[data-error-name="' + error + '"]').text(errors[error][i]);
                    }
                }
            }
        }

        // If no errror message was set return global error.
        if(error_set == false){
            $errors.find('[data-error-name="global_error_message"]').text(Lang.get('frontend.js.global.error'));
        }
    }

    // The updatePrices function with a delay of 200 milliseconds
    self.debouncedUpdatePrices = self.debounce(self.updatePrices, 200);
    self.debouncedCheckCouponCode = self.debounce(self.checkCouponCode, 200);
}
